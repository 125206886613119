@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-color: #101216;
  --slide-width: 100%;
  --slide-height: calc(100dvh - 16px);
  --slide-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.4);
  --slide-thumb-height: 3px;
  --slide-thumb-default-color: rgba(0, 0, 0, 0.4);
  --slide-thumb-active-color: rgba(255, 255, 255, 0.9);
}

.lf-player-container {
  height: 100%;
  width: 100%;
}

body {
  background-color: var(--background-color);
}

img {
  display: block;
  max-width: 100%;
  height: var(--slide-height);
  object-fit: cover;
  object-position: center;
}

.slide {
  box-shadow: var(--slide-width);
  display: grid;
  margin: 8px;
  max-width: var(--slide-width);
  height: var(--slide-height);
}

.slide-items {
  border-radius: 5px;
  grid-area: 1/1;
  overflow: hidden;
  position: relative;
}

.slide-items > * {
  /* opacity: 0; */
  pointer-events: none;
  position: absolute;
  top: 0;
}

.slide-items .active {
  opacity: 1;
  pointer-events: initial;
  position: relative;
}

.slide-nav {
  display: grid;
  grid-area: 1/1;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  z-index: 1;
}

.slide-thumbs {
  display: flex;
  grid-column: 1 / 3;
  column-gap: 2px;
  margin: 0 8px;
}

.slide-thumb-item {
  background-color: var(--slide-thumb-default-color);
  border-radius: 3px;
  display: block;
  flex: 1;
  height: var(--slide-thumb-height);
  margin: 5px;
  overflow: hidden;
}

.slide-thumb-item.viewed::after {
  background-color: var(--slide-thumb-active-color);
  border-radius: 3px;
  content: "";
  display: block;
  height: inherit;
}
.slide-thumb-item.active::after {
  animation: thumb 5s forwards linear;
  background-color: var(--slide-thumb-active-color);
  border-radius: 3px;
  content: "";
  display: block;
  height: inherit;
  transform: translateX(-100%);
}

.slide-next,
.slide-prev {
  opacity: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@keyframes thumb {
  to {
    transform: initial;
  }
}
